import React from "react"

const BlogPostContent = ({ post }) => (
    <div className='mt-12 md:mt-20 text-base md:text-lg tracking-wide text-gray-700 bg-white px-4 md:px-5 md:w-5/12 md:mx-auto'>
        {post.htmlAst.children.map((elem) => {
            if (elem.tagName === 'h3') {
                return (
                    <h3 className='text-2xl font-extrabold uppercase mt-12 text-gray-800'>{elem.children[0].value}</h3>
                )
            }
            if (elem.tagName === 'p') {
                if (elem.children.length > 1) {
                    return (
                        <p className='text-base text-gray-700'>
                            {elem.children.map((child, index) => {
                                if (child.tagName === 'a') {
                                    if (index === 0)
                                        return (<a href={child.properties.href} className='md:leading-relaxed md:text-lg text-blueGray-700 float-left mr-1 underline font-semibold'>{child.children[0].value}</a>)
                                    else return (<a href={child.properties.href} className=' md:leading-relaxed text-blueGray-700 md:text-lg underline font-semibold'>{child.children[0].value}</a>)
                                }
                                else return (<span className='md:text-lg text-gray-700 md:leading-relaxed'>{child.value}</span>)
                            })}
                        </p>
                    )
                } else {
                    return (
                        <p className='md:text-lg text-gray-700 md:leading-relaxed'>{elem.children[0].value}</p>
                    )
                }
            }
            if (elem.tagName === 'ul') {
                return (
                    <ul className='list-disc list-inside'>{elem.children.filter(child => child.tagName === 'li').map((child) => (
                        <li className='md:text-lg text-gray-700 md:leading-relaxed'>{child.children[0].value}</li>
                    ))}</ul>
                )
            }
            if (elem.tagName === 'blockquote') {
                return (
                    <blockquote className='text-center font-semibold shadow-md p-5 italic'>{elem.children[1].children[0].value}</blockquote>
                )
            }
            if (elem.value === '\n') {
                return (
                    <br></br>
                )
            }

        })}
    </div>
)

export default BlogPostContent;
