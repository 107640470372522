import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from 'gatsby-plugin-image'
import BlogPostContent from '../components/blog_post_content'
import BlogPostCredit from '../components/blog_post_credit'
import SEO from '../components/seo'

const BlogPost = ({ location, data }) => {
  const post = data.markdownRemark;
  const postImage = data.postImage.edges[0].node.childImageSharp.gatsbyImageData
  const seoImage = post.frontmatter.featured.childImageSharp.resize
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        image={seoImage}
        pathname={location.pathname}
      />
      <section className='flex flex-col mt-8 md:mt-12 md:mx-auto'>
        <h3 className='text-center font-semibold text-3xl md:text-4xl text-gray-800'>{post.frontmatter.title}</h3>
        <p className='text-center mt-4 font-medium text-base md:text-lg text-gray-700'>{post.frontmatter.date}</p>
        <GatsbyImage className='mt-10 md:w-3/5 md:mx-auto h-64 md:h-blogimage shadow-3xl' image={postImage} alt='Blog post full image' />
        <BlogPostContent post={post} />
        <BlogPostCredit frontmatter={post.frontmatter} />
      </section>
    </Layout >
  )
}

export default BlogPost;

export const query = graphql`
  query($slug: String!, $image: String!) {
    site {
      siteMetadata {
        title
        author
      }
    } 
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        thanks
        credit
        date(formatString: "DD MMMM, YYYY")
        featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      fields {
        slug
      }
    }
    postImage: allFile(
      filter: {extension: {eq: "jpg"}, base: {eq: $image}}
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
