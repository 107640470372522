import React from "react"

const BlogPostCredit = ({ frontmatter }) => {
    return (
        <div className='mt-12 md:mt-20 text-base md:text-lg tracking-wide text-gray-700 bg-white px-4 md:px-5 md:w-5/12 md:mx-auto'>
            <p className='font-bold'>If you found this essay useful, share it with your friends. 🚀 Also, say 👋 on <a href='https://twitter.com/inktsg' className='underline font-semibold'>twitter</a>.</p>
            {frontmatter.thanks != null &&
                <p className='mt-5' dangerouslySetInnerHTML={{ __html: frontmatter.thanks }} />
            }
            {frontmatter.credit != null &&
                <p className='mt-5' dangerouslySetInnerHTML={{ __html: frontmatter.credit }} />
            }
        </div>
    )
}

export default BlogPostCredit;